import React from 'react';
import { ActionType } from '../context/ActionTypes';
import { initialState, MessagingState } from '../context/Context';
import { useTranslation } from 'react-i18next';
import { useDiscussionThreads } from './hooks/useDiscussionThreads';
import { FolderSelection } from '../services/FolderService';
import SearchResultPagination from './SearchResultPagination';

const SearchResultList = () => {
    const {t} = useTranslation();
    const { state: { searchPayload }, dispatch } = MessagingState();
    const { getDiscussionThreads } = useDiscussionThreads();


    return (
        <ul 
            aria-labelledby='searchResultList'
            className="list-group full-height">
            <li
                className="list-group-item list-group-title">
                <div className="row">                        
                    <div className="col-md col-sm">
                        <button
                            className="close"
                            aria-label={t('labels.closeSearchResults')}
                            type='button'
                            tabIndex={0}
                            onClick={() => {
                                dispatch({
                                    type: ActionType.SET_SEARCH_PAYLOAD,
                                    payload: {...searchPayload, searchText: ''}
                                });
                                dispatch({
                                    type: ActionType.SET_SEARCH_FOCUS,
                                    payload: false
                                });
                                dispatch({
                                    type: ActionType.SET_SEARCH_VIEW,
                                    payload: false
                                });
                                const searchPayloadFolder = searchPayload.folder.folderSelection === FolderSelection.All && initialState.activeFolder ? initialState.activeFolder : searchPayload.folder;
                                getDiscussionThreads(searchPayloadFolder);
                            }}>
                        </button>
                        <span className="discussion-thread-list-group-title">{t('searchResults')}</span>
                    </div>
                </div>
            </li>
            <SearchResultPagination
                searchText={searchPayload.searchText}
                folderSelection={searchPayload.folder.folderSelection}/>
        </ul>
    );
};

export default SearchResultList;
