
import { messagingHttpClient } from '../http-common';
import { IDiscussionThread, IDiscussionThreadSelectPayload, IDiscussionThreadCreatePayload, IDiscussionThreadReplyPayload, IPagedRequestPayload, IPagedResponsePayload, IPaginationData } from '../@types/discussionthread';
import { CanceledError } from 'axios';
import { ISearchPayload, ISearchResponse, ISearchResult } from '../@types/search';

class DiscussionThreadService {

    private abortController = new AbortController();
    private discussionThreadAbortController = new AbortController();

    getReceived = async (payload? : IPagedRequestPayload): Promise<IPagedResponsePayload> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        try {
            const queryString = payload ? `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}` : '';
            const response = await messagingHttpClient.get<Array<IDiscussionThread>>('/ms/messaging/discussionthread/received' + queryString, { signal: this.abortController.signal });
            const data = response.data?.map(discussionThread =>
            // Convert backend sentAt strings to JS dates for processing
                ({
                    ...discussionThread, messages: discussionThread.messages.map(message => ({ ...message, sentAt: new Date(message.sentAt) }))
                })
            );
            const headers : IPaginationData = JSON.parse(response.headers['x-pagination']);

            return {
                discussionThreads: data,
                paginationData: {
                    TotalCount: headers.TotalCount,
                    PageSize: headers.PageSize,
                    CurrentPage: headers.CurrentPage,
                    TotalPages: headers.TotalPages
                }
            };

        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        TotalCount: 0,
                        PageSize: 0,
                        CurrentPage: 0,
                        TotalPages: 0
                    }
                };
            } else {
                throw e;
            }
        }
    };

    InboxUnreadCount() {
        return messagingHttpClient.get<number>('/ms/messaging/discussionthread/unread');
    }

    create(data: IDiscussionThreadCreatePayload) {
        return messagingHttpClient.post<IDiscussionThreadSelectPayload>('/ms/messaging/discussionthread', data);
    }

    createReplyToSender(data: IDiscussionThreadReplyPayload) {
        return messagingHttpClient.post<IDiscussionThread>('/ms/messaging/discussionthread/replytosender', data);
    }

    reply(data: string, id: string) {
        return messagingHttpClient.post<IDiscussionThread>('/ms/messaging/discussionthread/' + id, { reply: data });
    }

    getSent = async (payload? : IPagedRequestPayload): Promise<IPagedResponsePayload> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        try {
            const queryString = payload ? `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}` : '';
            const response = await messagingHttpClient.get<Array<IDiscussionThread>>('/ms/messaging/discussionthread/sent' + queryString, { signal: this.abortController.signal });
            const data = response.data?.map(discussionThread =>
            // Convert backend sentAt strings to JS dates for processing
                ({
                    ...discussionThread, messages: discussionThread.messages.map(message => ({ ...message, sentAt: new Date(message.sentAt) }))
                })
            );

            const headers : IPaginationData = JSON.parse(response.headers['x-pagination']);

            return {
                discussionThreads: data,
                paginationData: {
                    TotalCount: headers.TotalCount,
                    PageSize: headers.PageSize,
                    CurrentPage: headers.CurrentPage,
                    TotalPages: headers.TotalPages
                }
            };

        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        TotalCount: 0,
                        PageSize: 0,
                        CurrentPage: 0,
                        TotalPages: 0
                    }
                };
            } else {
                throw e;
            }
        }
    };

    search = async (searchPayload: ISearchPayload) : Promise<ISearchResponse> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        try {
            const response =  await messagingHttpClient.post<Array<ISearchResult>>('/ms/messaging/Search', {...searchPayload }, {signal: this.abortController.signal});
            const data = response.data;
            const headers : IPaginationData = JSON.parse(response.headers['x-pagination']);

            const results = {
                discussionThreads: data,
                paginationData: {
                    TotalCount: headers.TotalCount,
                    PageSize: headers.PageSize,
                    CurrentPage: headers.CurrentPage,
                    TotalPages: headers.TotalPages
                }
            };

            return results;
        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        TotalCount: 0,
                        PageSize: 0,
                        CurrentPage: 0,
                        TotalPages: 0
                    }
                };
            } else {
                throw e;
            }
        }
    };

    updateRecipientList = (recipientGuids: string[], threadId: string) => {
        return messagingHttpClient.patch<IDiscussionThread>(`/ms/messaging/discussionthread/${threadId}`, recipientGuids);
    };

    getDiscussionThread = async (threadId: string) => {
        this.discussionThreadAbortController.abort();
        this.discussionThreadAbortController = new AbortController();
        try {
            const response = await messagingHttpClient.get<IDiscussionThread>(`/ms/messaging/discussionthread/${threadId}`, { signal: this.discussionThreadAbortController.signal });
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return null;
            } else {
                throw e;
            }
        }
    };

    setMessageAsRead = (threadId: string, messageId: string) => {
        return messagingHttpClient.post<void>(`/ms/messaging/discussionthread/${threadId}/markread`, { messageId });
    };
}

export default new DiscussionThreadService();
