import { Icon } from '@vismaux/react-vud';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { areStringArraysEqual } from '../utils/utils';
import { IDiscussionThread } from '../@types/discussionthread';
import { ActionType } from '../context/ActionTypes';
import { MessagingState } from '../context/Context';
import { useToast } from '../context/ToastContext';
import DiscussionThreadService from '../services/DiscussionThreadService';
import WilmaAsyncSelect, { StringOption } from './WilmaAsyncSelect/WilmaAsyncSelect';
import RecipientService from '../services/RecipientService';
import { IUser } from '../@types/user';

export interface QuickAddRecipientProps {
    discussionThread: IDiscussionThread,
    setIsAddingRecipients: (flag: boolean) => void,
    recipients: IUser[]
    displayLimit: number
    placeholder?: string
    isAddingRecipients?: boolean
    hiddenRecipientAmount?: number
}

const QuickAddRecipients = ({discussionThread, setIsAddingRecipients, recipients, hiddenRecipientAmount, displayLimit, placeholder, isAddingRecipients}: QuickAddRecipientProps) => {
    const { t } = useTranslation();
    const { createToast } = useToast();
    const { state: {selectedRecipients}, dispatch } = MessagingState();
    const [originalRecipients, setOriginalRecipients] = useState<StringOption[]>(mapIUserToOption(recipients));
    const { handleSubmit, control, formState: { errors, isSubmitting } } = useFormContext();

    const filterExistingRecipients = (
        recipientRecords: StringOption[],
        excludedRecipients: string[]
    ) => {
        const excludedRoleGuids = excludedRecipients.map((record) => record);
        return recipientRecords.filter((record) => !excludedRoleGuids.includes(record.value)
        );
    };

    useEffect(() => {
        const shouldInitialize = isAddingRecipients && selectedRecipients.options.length === 0;
        const shouldReset = !isAddingRecipients && !areStringArraysEqual(originalRecipients.map(x => x.value), selectedRecipients.options.map(x => x.value));
        if (shouldInitialize || shouldReset) {
            dispatch({
                type: ActionType.SET_SELECTED_RECIPIENTS, payload: { options: originalRecipients }
            });        
        }
    }, [isAddingRecipients, selectedRecipients, originalRecipients, dispatch]);

    // Simplify the valueToUse function
    const valueToUse = () => {
        if (isAddingRecipients) {
            return [...selectedRecipients.options];
        } else {
            return originalRecipients;
        }
    };
    // sets isAddingRecipients to false when discussionThread changes
    useEffect(() => {
        setIsAddingRecipients(false);
    }, [discussionThread, setIsAddingRecipients] );

    useEffect(() => {
        setOriginalRecipients(mapIUserToOption(recipients));
    }, [recipients] );

    const promiseOptions = async (inputValue: string) =>  {
        if (!isAddingRecipients) {
            return { options: [], hasMore: false }; 
        }
        const response = await RecipientService.searchRecipients(inputValue);
        const records = response.data.allRecords.map(record => {
            const option: StringOption = {label: record.label, value: record.roleGuid, name: record.name, nameAbbreviation: record.nameAbbreviation, allowMessaging: record.allowSendingMessage, roleType: record.roleType, schoolNames: record.schoolNames, extraInfo: record.extraInfo, isGroup: false};
            return option;
        });
        const filteredRecipients = filterExistingRecipients(records, discussionThread.recipients.map(x=>x.roleGuid))
            .filter(user => user.value !== discussionThread.author?.roleGuid);
        // hasMore false since API doesn't support pagination
        return { options: filteredRecipients, hasMore: false };
    };

    const submitDisabled = isSubmitting || selectedRecipients.options.length === originalRecipients.length;

    const handleChange = (options: readonly StringOption[]) => {
        const latestOption = options[options.length-1];
        const newRecipientsList = [latestOption, ...options.slice(0, options.length-1)];
        dispatch({
            type: ActionType.SET_SELECTED_RECIPIENTS, payload: { options: newRecipientsList}
        });
    };

    function mapIUserToOption(recipients: IUser[]): StringOption[] {
        return recipients.map(recipient => {
            const option: StringOption = {
                label: recipient.label,
                value: recipient.roleGuid,
                name: recipient.label,
                nameAbbreviation: '',
                allowMessaging: true,
                roleType: recipient.roleType,
                schoolNames: [],
                extraInfo: '',
                isGroup: false
            };
            return option;
        });
    }
    
    const handleRecipientSubmit = async () => {   
        try {
            const updatedDiscussionThread = await DiscussionThreadService.updateRecipientList(selectedRecipients.options.map(o => o.value), discussionThread.id);
            dispatch({
                type: ActionType.UPDATE_DISCUSSION_THREAD,
                payload: updatedDiscussionThread.data
            });
        } catch (error) {
            createToast({
                title: t('errors.recipientModificationError'),
                toastType: 'danger'
            });
        }
        setIsAddingRecipients(false);
    };

    return (
        <div id="add-recipient-row">
            <form
                id='quick-add-recipients-form'
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && !isAddingRecipients) {
                        event.preventDefault();
                    }
                }}
                onSubmit={handleSubmit(() => handleRecipientSubmit())}>
                <label
                    className="container-label"
                    aria-label={t('inputs.recipientsList')}>
                    <Controller
                        name='recipients'
                        control={control}
                        render={({ field: { onChange, onBlur } }) => (
                            <WilmaAsyncSelect
                                onBlur={onBlur}
                                disableAutofocus={true}
                                displayLimit={displayLimit}
                                value={valueToUse()}
                                recipients={recipients}
                                hiddenRecipientAmount={hiddenRecipientAmount}
                                ariaInvalid={errors.recipients ? 'true' : 'false'}
                                isSearchable={isAddingRecipients}
                                placeholder={!placeholder ? t('placeholders.recipients') : isAddingRecipients ? placeholder : ''}
                                loadingMessage={() => t('loading')}
                                loadOptions={promiseOptions}
                                hideMenu={!isAddingRecipients}
                                hideRemoveButton={!isAddingRecipients}
                                ariaLabel={!placeholder ? t('placeholders.recipients') : placeholder}
                                noOptionsMessage={() => t('noRecipientsFound')}
                                readonlyUsers={originalRecipients.map(x=>x.value)}
                                onChange={e => {
                                    handleChange(e);
                                    onChange(e);
                                }}
                                hasErrors={errors.recipients ? true : false}
                            />
                        )}
                    />
                </label>
                {isAddingRecipients && (
                    <button
                        id='quick-recipient-add-submit-button'
                        type="submit"
                        disabled={submitDisabled}
                        className='btn btn-primary bg-secondary action-button icon-button'
                        aria-disabled={submitDisabled}
                        aria-label={t('buttons.submitNewRecipients')}>
                        <Icon
                            name='ok-circle'
                            size='lg'
                        />
                    </button>
                )}
                {
                    isSubmitting && (
                        <span className="spinner spinner-sm spinner-default-blue"></span>
                    )

                }
            </form>
        </div>
    ); 
};

export default QuickAddRecipients;