import React from 'react';
import { IDiscussionThread } from '../@types/discussionthread';
import { getNewestMessageSentAt, getUserDisplayName } from '../utils/utils';
import { useTranslation } from 'react-i18next';

interface Props {
    discussionThread: IDiscussionThread
}
const DiscussionThreadListItem = (props: Props) => {
    const {t} = useTranslation();
    const discussionThread = props.discussionThread;

    return (
        <>
            {discussionThread ? (
                <div
                    className="row discussionThreadListItem"
                    aria-label={t('labels.discussionThreadItem')}>                    
                    <div className="col-md-12">
                        <span>
                            {getUserDisplayName(discussionThread.messages.length > 0 ?
                                discussionThread.messages[discussionThread.messages.length-1].author :
                                discussionThread.author)}
                        </span>
                        <div className="row">
                            <div className="col-8 truncate">
                                {discussionThread.name}
                            </div>
                            <div className="col-4 date">
                                {getNewestMessageSentAt(discussionThread)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 truncate">                                
                                {
                                    discussionThread.messages.length > 0
                                && discussionThread.messages[discussionThread.messages.length - 1]
                                && discussionThread.messages[discussionThread.messages.length - 1].contents
                                && discussionThread.messages[discussionThread.messages.length - 1].contents[0].contentString
                                }                                
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>{t('discussionThreadMissing')}</div>
            )
            }
        </>
    );
};

export default DiscussionThreadListItem;
