import React from 'react';
import { MessagingState } from '../context/Context';
import { useTranslation } from 'react-i18next';
import { IDiscussionThread, IPagedResponsePayload } from '../@types/discussionthread';
import { ActionType } from '../context/ActionTypes';
import DiscussionThreadListItem from './DiscussionThreadListItem';
import classNames from 'classnames';
import { getThreadUnreadMessages } from '../utils/utils';
import DiscussionThreadService from '../services/DiscussionThreadService';
import { FolderSelection } from '../services/FolderService';
import { useToast } from '../context/ToastContext';
import Paginate from './Paginate';
import { LoadingState } from '../@types/loadingstates';
import { Icon, Spinner } from '@vismaux/react-vud';

const ITEMS_PER_PAGE = 10;

const DiscussionThreadPagination = () => {
    const { t } = useTranslation();
    const { state: { discussionThreads, activeDiscussionThread, activeFolder, paginationData, discussionThreadsLoading }, dispatch } = MessagingState();
    const toast = useToast();

    const fetchData = async (currentPage: number) => {
        if (!activeFolder) return;

        let response: IPagedResponsePayload = {discussionThreads: [], paginationData: {
            TotalPages: 1,
            CurrentPage: 0,
            PageSize: 0,
            TotalCount: 0
        }};
        try {
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Loading
            });
            switch (activeFolder?.folderSelection) {
                case FolderSelection.Received:
                    response = await DiscussionThreadService.getReceived({pageNumber: currentPage, pageSize: ITEMS_PER_PAGE});
                    break;
                case FolderSelection.Sent:
                    response = await DiscussionThreadService.getSent({pageNumber: currentPage, pageSize: ITEMS_PER_PAGE});
                    break;
                default:
                    break;
            }
            if (response.paginationData.CurrentPage !== 0) {
                dispatch({
                    type: ActionType.SET_DISCUSSIONTHREADS,
                    payload: response.discussionThreads
                });
                dispatch({
                    type: ActionType.SET_PAGINATION_DATA,
                    payload: { currentPage: response.paginationData.CurrentPage, totalPages: response.paginationData.TotalPages}
                });
                dispatch({
                    type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                    payload: LoadingState.Done
                });
            }
        } catch {
            toast.createToast({
                title: t('errors.messageLoadingError'),
                toastType: 'danger'
            });
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Error
            });
        }
    };

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLLIElement>, discussionThread:IDiscussionThread) => {
        if (ev.key === 'Enter') {
            setActiveDiscussionThread(discussionThread);
        }
    };

    const setActiveDiscussionThread = async (discussionThread:IDiscussionThread) => {
        // Don't retrigger new fetch
        if (activeDiscussionThread?.id === discussionThread.id) {
            return;
        }

        dispatch({
            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
            payload: { thread: discussionThread, loadingState: LoadingState.Loading } 
        });
        try {
            const discussionThreadContent = await DiscussionThreadService.getDiscussionThread(discussionThread.id);
            if (discussionThreadContent) {
                dispatch({
                    type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                    payload: { thread: discussionThreadContent, loadingState: LoadingState.Done } 
                });
            }
        } catch (e) {
            console.error(e);
            dispatch({
                type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                payload: { thread: discussionThread, loadingState: LoadingState.Error } 
            });
        }
    };

    const handlePageClick = (event: { selected: number; }) => {
        fetchData(event.selected + 1);
    };
  
    return (
        <>
            {discussionThreadsLoading === LoadingState.Loading && (
                <li className='center'>
                    <Spinner />
                </li>
            )}
            {discussionThreadsLoading === LoadingState.Error && (
                <li className='center'>
                    <Icon
                        name='error'
                        size='sm' />
                    <span className='error-text'>{t('errors.discussionThreadLoadingFailed')}</span>
                </li>
            )}
            {discussionThreadsLoading === LoadingState.Done &&
                    discussionThreads?.map((discussionThread: IDiscussionThread) => {
                        const unreadMessageCount = getThreadUnreadMessages(discussionThread).length;
                        const discussionThreadListItemWrapperClass = classNames('list-group-item',
                            { active: activeDiscussionThread?.id === discussionThread.id },
                            { 'isUnreadThread': unreadMessageCount > 0 });
                        return (
                            <li
                                aria-label={activeDiscussionThread?.id === discussionThread.id ? t('chosenMessage') : ''}
                                className={discussionThreadListItemWrapperClass}
                                key={discussionThread.id}
                                id={`discussionThread${discussionThread.id}`}
                                tabIndex={0}
                                onClick={() => setActiveDiscussionThread(discussionThread)}
                                onKeyDown={(e) => handleKeyDown(e, discussionThread)}
                            >{<DiscussionThreadListItem discussionThread={discussionThread} />}
                            </li>
                        );
                    })
            }
            <li className='list-group-item list-group-pagination'>
                <Paginate
                    currentPage={paginationData.currentPage}
                    totalPages={paginationData.totalPages}
                    handlePageClick={handlePageClick} />
            </li>

        </>
    );
};

export default DiscussionThreadPagination;