import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const isDevelopmentEnv = process.env.NODE_ENV === 'development';

i18next.use(Backend).use(LanguageDetector).use(initReactI18next)
    .init({
        backend: {
            loadPath: '/ms/messagingfrontend/locales/{{lng}}/{{ns}}.json'
        },
        debug: isDevelopmentEnv,
        saveMissing: isDevelopmentEnv,
        saveMissingTo: 'all',
        fallbackLng: 'en',
        // multiple namespaces possible
        ns: ['translation'],
        supportedLngs: ['en', 'fi'],
        load: 'languageOnly',
        missingKeyHandler: (lng, ns, key, fallbackValue) => {
            if(isDevelopmentEnv){
                i18next.addResource('en',ns, key, '<<<Set Key to translation!>>>');
                console.error(lng, ns, key, fallbackValue);
            }    
        }
    });

i18next.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
});

export default i18next;