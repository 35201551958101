import React from 'react';
import { IMessage } from '../@types/message';
import { getAddedRecipients, getUserDisplayName } from '../utils/utils';
import { IUser } from '../@types/user';
import { useTranslation } from 'react-i18next';
import { IDiscussionThread } from '../@types/discussionthread';

interface Props {
    discussionThread: IDiscussionThread;
    message: IMessage;
    index: number;
}

const DiscussionThreadUserAdded = (props: Props) => {
    const {t} = useTranslation();

    return (
        <div>
            {/* Check for added recipients and render their messages */}
            {getAddedRecipients(props.index, props.discussionThread, props.message).map((recipient: IUser) => (
                <div
                    key={`${recipient.roleGuid}`}
                    className='user-added-container'>
                    <p>{t('userAddedMessage', {fullName: `${getUserDisplayName(recipient)}`})}</p>
                </div>
            ))}
        </div>
    );
};

export default DiscussionThreadUserAdded;
