import React from 'react';
import { MessagingState, NEW_DISCUSSION_THREAD_ID } from '../context/Context';
import DiscussionThread from './DiscussionThread';
import DiscussionTitle from './DiscussionTitle';
import NewDiscussionThread from './NewDiscussionThread/NewDiscussionThread';
import MessageSentPage from './NewDiscussionThread/MessageSent';
import { useTranslation } from 'react-i18next';
import DiscussionThreadReply from './DiscussionThreadReply';
import SelectRecipientWrapper from './SelectRecipients/SelectRecipientsWrapper';
import { LoadingState } from '../@types/loadingstates';
import { Spinner } from '@vismaux/react-vud';

const DiscussionThreadWrapper = () => {
    const { t } = useTranslation();
    const { state: { activeDiscussionThread, threadLoadingState, sentMessageView, selectRecipientView } } = MessagingState();

    return (
        <div
            id="discussion-thread-wrapper"
            className="full-height">
            {activeDiscussionThread && activeDiscussionThread.id !== NEW_DISCUSSION_THREAD_ID ? (
                <div className='container-fluid d-flex flex-column h-100 no-gutters'>
                    <div className='row no-gutters'>
                    </div>
                    {threadLoadingState === LoadingState.Loading && (
                        <div className='center'>
                            <Spinner/>
                        </div>
                    )}
                    {threadLoadingState === LoadingState.Error && (
                        <div className='center'>
                            <span className='error-text'>{t('errors.discussionThreadDataLoadingFailed')}</span>
                        </div>
                    )}
                    {threadLoadingState === LoadingState.Done && (
                        <>
                            <DiscussionTitle
                                discussionThread={activeDiscussionThread}/>
                            <hr className='row no-gutters' />
                            <div
                                id="discussionthread-messages-container"
                                className="row wrapper justify-content-start flex-grow-1 no-gutters">
                                <DiscussionThread
                                    discussionThread={activeDiscussionThread}/>
                            </div>
                            <hr className='row no-gutters' />
                            <div className='row justify-content-end no-gutters'>
                                <DiscussionThreadReply />
                            </div>
                        </>
                    )}
                </div>
            ) : activeDiscussionThread && activeDiscussionThread.id === NEW_DISCUSSION_THREAD_ID ? (
                <NewDiscussionThread />
            ) : sentMessageView ? (
                <MessageSentPage />
            ) : selectRecipientView ? (
                <SelectRecipientWrapper />
            ) :
                (
                    <>
                        <div className="no-discussion-thread-selected">
                            <strong>{t('no-discussion-thread-selected-title')}</strong>
                        </div>
                        <div className="no-discussion-thread-selected">
                            <span>{t('no-discussion-thread-selected-desc')}</span>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default DiscussionThreadWrapper;