import React from 'react';
import { IRecord } from '../../@types/recipients';
import classNames from 'classnames';

interface RecipientPersonButtonProps {
    name: string;
    role: string;
    record: IRecord;
    onClickSelectRecipient: (event: React.MouseEvent<HTMLButtonElement>, record: IRecord) => void,
    isSelected: (record: IRecord) => boolean;
}

const RecipientPersonButton = ({ name, role, record, onClickSelectRecipient, isSelected } : RecipientPersonButtonProps) => {
    return (
        <button
            className={classNames('recipient-list-group-item-3', { 'disabled': !record.allowSendingMessage, 'active': isSelected(record) })}
            onClick={record.allowSendingMessage ? (e) => onClickSelectRecipient(e, record) : undefined}>
            <span className="recipient-person-name">{name} </span>
            {(record.roleType === 'Student' || record.roleType === 'Guardian' || record.roleType === 'OldStyleGuardian') && <br />}
            <span className="recipient-list-text">{role}</span>
        </button>
    );
};

export default RecipientPersonButton;