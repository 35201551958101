
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFolder } from '../@types/folder';
import { useToast } from '../context/ToastContext';
import useDetectOutsideClick from './hooks/useDetectOutsideClick.';
import DiscussionThreadService from '../services/DiscussionThreadService';
import { ISearchPayload } from '../@types/search';
import { MessagingState } from '../context/Context';
import { ActionType } from '../context/ActionTypes';
import FolderService, { FolderSelection } from '../services/FolderService';
import * as amplitude from '@amplitude/analytics-browser';
import { LoadingState } from '../@types/loadingstates';
import classNames from 'classnames';

const SearchBar = () => {
    const { t } = useTranslation();
    const folders = FolderService.getAll();
    const { createToast } = useToast();
    const { state: { searchFocus, searchPayload }, dispatch } = MessagingState();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const firstMenuItemRef = useRef<HTMLLIElement>(null);
    useDetectOutsideClick(wrapperRef, () => setIsOpen(false));

    const { handleSubmit } =
        useForm({
            defaultValues: {
                searchText: searchPayload.searchText,
                folderSelection: FolderSelection.All,
            }
        });

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        dispatch({
            type: ActionType.SET_SEARCH_PAYLOAD,
            payload: { ...searchPayload, searchText: e.currentTarget.value }
        });
    };

    const handleKeyDownButton = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (!isOpen) {
                setIsOpen(true);
                setSelectedItemIndex(0);
            } else if (firstMenuItemRef.current) {
                firstMenuItemRef.current.focus();
                setSelectedItemIndex(0);
            }
        } else if (e.key === 'Enter') {
            handleOpen();
        }
    };

    const handleKeyDownMenuItem = (
        e: React.KeyboardEvent<HTMLLIElement>,
        index: number
    ) => {
        if (e.key === 'ArrowDown' && index < folders.length - 1) {
            e.preventDefault();
            setSelectedItemIndex(index + 1);
            const nextMenuItem = wrapperRef.current?.querySelector(
                `[data-index="${index + 1}"]`
            ) as HTMLLIElement | null;
            if (nextMenuItem) {
                nextMenuItem.focus();
            }
        } else if (e.key === 'ArrowUp' && index > 0) {
            e.preventDefault();
            setSelectedItemIndex(index - 1);
            const prevMenuItem = wrapperRef.current?.querySelector(
                `[data-index="${index - 1}"]`
            ) as HTMLLIElement | null;
            if (prevMenuItem) {
                prevMenuItem.focus();
            }
        } else if (e.key === 'Enter') {
            handleDropdownItemClick(e, folders[index]);
        }
    };

    const handleDropdownItemClick = (e: React.FormEvent, folder: IFolder) => {
        //prevents keydown event bubbling up to search input and initiating search
        e.preventDefault();
        dispatch({
            type: ActionType.SET_SEARCH_PAYLOAD,
            payload: { ...searchPayload, folder: folder }
        });
        setIsOpen(false);
        document.getElementById('search-dropdown-button')?.focus();
    };

    const handleOpen = () => {
        setIsOpen(!isOpen);
        setSelectedItemIndex(-1);
    };

    const onSubmit = async () => {
        if(searchPayload.searchText == '')
            return;
        amplitude.track('search_submitted', {
            search_text : searchPayload.searchText
        });
        dispatch({
            type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
            payload: LoadingState.Loading
        });
        try {
            const searchParam: ISearchPayload = {
                searchText: searchPayload.searchText,
                folderSelection: searchPayload.folder.folderSelection
            };
            dispatch({
                type: ActionType.SET_SEARCH_VIEW,
                payload: true
            });
            const searchResult = await DiscussionThreadService.search(searchParam);
            dispatch({
                type: ActionType.SET_SEARCH_DISCUSSIONTHREADS,
                payload: searchResult.discussionThreads
            });
            dispatch({
                type: ActionType.SET_PAGINATION_DATA,
                payload: { currentPage: searchResult.paginationData.CurrentPage, totalPages: searchResult.paginationData.TotalPages }
            });
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Done
            });
        } catch (error) {
            createToast({
                title: t('errors.searchError'),
                toastType: 'danger'
            });
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Error
            });
        }
    };

    const setSearchFocus = (isFocus: boolean) => {
        dispatch({
            type: ActionType.SET_SEARCH_FOCUS,
            payload: isFocus
        });
    };

    const onFocus = () => {
        setSearchFocus(true);
    };

    const focusInCurrentTarget = ({ relatedTarget, currentTarget }: React.FocusEvent<HTMLInputElement>) => {
        if (relatedTarget === null) return false;

        let node = relatedTarget.parentNode;

        while (node !== null) {
            if (node === currentTarget) return true;
            node = node.parentNode;
        }
        return false;
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!focusInCurrentTarget(e) && !searchPayload.searchText && !isOpen ) {
            setSearchFocus(false);
        }
    };

    return (
        <div
            onBlur={onBlur}
            className={classNames('middle-row', searchFocus ? 'col-md-9' : 'col-md-3')}>
            <form
                onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group row no-gutters">
                    {searchFocus && (
                        <div
                            className='col-md-auto'>
                            <div
                                ref={wrapperRef}
                                className={isOpen ? 'dropdown open' : 'dropdown'}
                            >
                                <button
                                    ref={buttonRef}
                                    type="button"
                                    className="btn btn-primary bg-secondary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-controls="folder-menu"
                                    aria-expanded={isOpen ? 'true' : 'false'}
                                    id="search-dropdown-button"
                                    onKeyDown={(e) => handleKeyDownButton(e)}
                                    onClick={handleOpen}
                                >{t(searchPayload.folder.name)}
                                </button>
                                <ul
                                    id="folder-menu"
                                    className="dropdown-menu"
                                    role="menu"
                                    tabIndex={0}
                                    aria-hidden={!isOpen}
                                    aria-expanded={isOpen}
                                    aria-labelledby="search-dropdown-button"
                                >
                                    {folders.map((folder, index) => {
                                        // TODO: Remove isDisabled when all folders are implemented
                                        const isDisabled = folder.folderSelection === FolderSelection.Disabled;
                                        const isSelected = index === selectedItemIndex;
                                
                                        return (
                                            <li
                                                className={`dropdown-item${isDisabled ? ' disabled' : ''}${
                                                    isSelected ? ' selected' : ''
                                                }`}
                                                role="menuitem"
                                                tabIndex={-1}
                                                data-index={index}
                                                onKeyDown={(e) => handleKeyDownMenuItem(e, index)}
                                                key={'searchFolder' + folder.id}
                                                ref={index === 0 ? firstMenuItemRef : null}
                                                onClick={(e) => handleDropdownItemClick(e,folder)}
                                            >
                                                <a href="#">{t(folder.name)}</a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className={searchFocus ? 'col-md-5' : 'col-md-12'}>
                        <div className=" search-group search-group-primary">
                            <input
                                id="search-bar"
                                onFocus={onFocus}
                                className="form-control"
                                type="search"
                                role="search"
                                onChange={handleChange}
                                value={searchPayload.searchText}
                                placeholder={t('placeholders.search')}
                                aria-label={t('searchInput')} />
                            {searchFocus && (
                                <button
                                    className="btn search-icon"
                                    id='search-button'
                                    name='searchBtn'
                                    aria-label={t('labels.searchButton')}
                                    title={t('search')}
                                    tabIndex={0}
                                    type="submit"
                                />
                            )}
                            <span className="search-icon" />
                            <span className="clear-search" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SearchBar;
