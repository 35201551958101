
import { IFolder } from '../@types/folder';

export enum FolderSelection{
    All,
    Received,
    Sent,
    Favorite,
    Outbox,
    Draft,
    Archive,
    Trash,
    // TODO remove when all folders are implemented
    Disabled
}

class FolderService {
    getAll(): IFolder[] {
        return (Array<IFolder>(
            { id: 0, name: 'folders.all', icon: 'email', folderSelection: FolderSelection.All, path: '/', discussionThreads: [], hideInFolderList: true },
            { id: 1, name: 'folders.inbox', icon: 'email', folderSelection: FolderSelection.Received, path: 'received', discussionThreads: [] },
            { id: 2, name: 'folders.sent', icon: 'paperplane', path: 'sent', folderSelection: FolderSelection.Sent, discussionThreads: [] },
            { id: 3, name: 'folders.favorites', icon: 'favourite', path: 'favourite', folderSelection: FolderSelection.Disabled,  discussionThreads: [] },
            { id: 4, name: 'folders.outbox', icon: 'reminder', path: 'outbox', folderSelection: FolderSelection.Disabled, discussionThreads: [] },
            { id: 5, name: 'folders.drafts', icon: 'text-document', path: 'draft', folderSelection: FolderSelection.Disabled, discussionThreads: [] },
            { id: 6, name: 'folders.archive', icon: 'folder-building', path: 'archive', folderSelection: FolderSelection.Disabled, discussionThreads: [] },
            { id: 7, name: 'folders.trash', icon: 'delete', path: 'trash', folderSelection: FolderSelection.Disabled, discussionThreads: [] }
        ));
    }
}

export default new FolderService();
