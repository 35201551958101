import { IApiFeatureFlags, IFeatureFlags } from '../@types/featureflag';

import { messagingHttpClient } from '../http-common';

export const fetchFeatureFlags = async () => {
    const featureFlagData = (await messagingHttpClient.get<IApiFeatureFlags[]>('/ms/messaging/featureflag')).data;

    const result: IFeatureFlags = {
        collectAmplitudeData: featureFlagData.find((flag) => flag.configName === 'collectAmplitudeData')?.isEnabled ?? false
    };
    return result;
};
