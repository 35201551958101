import React from 'react';
import { MessagingState } from '../context/Context';
import { useTranslation } from 'react-i18next';
import { IDiscussionThread } from '../@types/discussionthread';
import { ActionType } from '../context/ActionTypes';
import DiscussionThreadListItem from './DiscussionThreadListItem';
import classNames from 'classnames';
import { ISearchResult } from '../@types/search';
import { FolderSelection } from '../services/FolderService';
import Paginate from './Paginate';
import { Spinner, Icon } from '@vismaux/react-vud';
import { LoadingState } from '../@types/loadingstates';
import DiscussionThreadService from '../services/DiscussionThreadService';

interface ISearchResultPaginationProps {
    searchText: string;
    folderSelection: FolderSelection;
}

const ITEMS_PER_PAGE = 10;

const SearchResultPagination = ({searchText, folderSelection} : ISearchResultPaginationProps) => {
    const { t } = useTranslation();
    const { state: { searchResponses, activeDiscussionThread, paginationData, discussionThreadsLoading}, dispatch } = MessagingState();

    const fetchData = async (currentPage: number) => {
        dispatch({
            type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
            payload: LoadingState.Loading
        });
        const response = await DiscussionThreadService.search({searchText: searchText, folderSelection: folderSelection, pageNumber: currentPage, pageSize: ITEMS_PER_PAGE});
        if (response.paginationData.CurrentPage !== 0) {
            dispatch({
                type: ActionType.SET_SEARCH_DISCUSSIONTHREADS,
                payload: response.discussionThreads
            });
            dispatch({
                type: ActionType.SET_PAGINATION_DATA,
                payload: { currentPage: response.paginationData.CurrentPage, totalPages: response.paginationData.TotalPages }
            });
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Done
            });
        }
    };

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLLIElement>, discussionThread:IDiscussionThread) => {
        if (ev.key === 'Enter') {
            setActiveDiscussionThread(discussionThread);
        }
    };

    const setActiveDiscussionThread = async (discussionThread:IDiscussionThread) => {
        if (activeDiscussionThread?.id === discussionThread.id) {
            return;
        }
        
        dispatch({
            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
            payload: { thread: discussionThread, loadingState: LoadingState.Loading }
        });
        try {
            const discussionThreadData = await DiscussionThreadService.getDiscussionThread(discussionThread.id);
            if (discussionThreadData) {
                dispatch({
                    type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                    payload: { thread: discussionThreadData, loadingState: LoadingState.Done }
                });
            }
        } catch (e) {
            console.error(e);
            dispatch({
                type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                payload: { thread: discussionThread, loadingState: LoadingState.Done }
            });
        }
    };

    const handlePageClick = (event: { selected: number; }) => {
        fetchData(event.selected + 1);
    };
  
    return (
        <>
            {discussionThreadsLoading === LoadingState.Loading && (
                <li className='center'>
                    <Spinner/>
                </li>
            )}
            {discussionThreadsLoading === LoadingState.Error && (
                <li className='center'>
                    <Icon
                        name='error'
                        size='sm'/>
                    <span className='error-text'>{t('errors.discussionThreadSearchFailed')}</span>
                </li>
            )}
            {discussionThreadsLoading === LoadingState.Done &&
                searchResponses?.map((searchResponse: ISearchResult) => {
                    const discussionThreadListItemWrapperClass = classNames('list-group-item',
                        {active: activeDiscussionThread?.id === searchResponse.discussionThread.id});
                    return (
                        <li
                            aria-label={activeDiscussionThread?.id === searchResponse.discussionThread.id ? t('chosenMessage') : ''}
                            className={discussionThreadListItemWrapperClass}
                            tabIndex={0}
                            key={'searchResponse' + searchResponse.discussionThread.id}
                            onClick={() => setActiveDiscussionThread(searchResponse.discussionThread)}
                            onKeyDown={(e) => handleKeyDown(e,  searchResponse.discussionThread)}
                        ><DiscussionThreadListItem discussionThread={searchResponse.discussionThread} />
                        </li>
                    );
                })
            }
            <li className='list-group-item list-group-pagination'>
                <Paginate
                    currentPage={paginationData.currentPage}
                    totalPages={paginationData.totalPages}
                    handlePageClick={handlePageClick}
                />
            </li>
        </>
    );
};

export default SearchResultPagination;