import React from 'react';
import SelectAllCheckbox from './SelectAllCheckbox';
import { IRecord, recordType } from '../../@types/recipients';
import { getGroupSelectionLabel, getUserDisplayName, splitLabel } from '../../utils/utils';
import { t } from 'i18next';
import RecipientPersonButton from './RecipientPersonButton';

interface RecipientPersonStudentsGuardiansProps {
    showElement: boolean;
    label: string;
    recordType: recordType;
    selectedSchoolId: string;
    activeButtonId: string;
    records: IRecord[];
    onClickSelectRecipient: (event: React.MouseEvent<HTMLButtonElement>, record: IRecord) => void;
    isSelected: (record: IRecord) => boolean;
}

const RecipientPersonStudentsGuardians = ({ activeButtonId, showElement, label, recordType, selectedSchoolId, records, onClickSelectRecipient, isSelected}: RecipientPersonStudentsGuardiansProps) => {
    const getGuardianRecords = (record: IRecord): IRecord[] => {
        return record.guardianRecords && record.guardianRecords.length > 0 
            ? record.guardianRecords 
            : [{name: '', label: t('selectRecipients.noGuardians'), roleGuid: '', roleType: '', schoolNames: [], allowSendingMessage: false, extraInfo: ''}];
    };
    
    return (
        <>
            {showElement && (
                <div className="list-group-recipients recipients-list">
                    <div className="grid-container">
                        <SelectAllCheckbox
                            key="select-all-checkbox"
                            id={activeButtonId}
                            selectedLabel={`${label} ${getGroupSelectionLabel(recordType, selectedSchoolId)}`}
                            recordType={recordType}/>
                        <SelectAllCheckbox
                            key="select-all-guardians-checkbox"
                            id={`${activeButtonId}-guardians`}
                            selectedLabel={`${label} ${getGroupSelectionLabel('studentGuardianRecords', selectedSchoolId)}`}
                            recordType='studentGuardianRecords'/>
                        {records?.map((record) => {
                            const [name, role] = splitLabel(getUserDisplayName(record));
                            const guardians = getGuardianRecords(record);
                            return (
                                <React.Fragment key={record.roleGuid}>
                                    <div
                                        className="button-row">
                                        <RecipientPersonButton
                                            name={name}
                                            role={role}
                                            record={record}
                                            key={`${record.roleGuid}-btn`}
                                            onClickSelectRecipient={onClickSelectRecipient}
                                            isSelected={isSelected}
                                        />
                                    </div>
                                    {guardians.length > 0 && (
                                        <div
                                            key={`${record.roleGuid}-guardians`}
                                            className="button-row">
                                            <div className="guardian-col">
                                                {guardians?.map((guardianRecord : IRecord, index: number) => {
                                                    const [guardianName, guardianRole] = splitLabel(getUserDisplayName(guardianRecord));
                                                    return (
                                                        <RecipientPersonButton
                                                            name={guardianName}
                                                            role={guardianRole}
                                                            record={guardianRecord}
                                                            key={`${guardianRecord.roleGuid}-${index}`}
                                                            onClickSelectRecipient={onClickSelectRecipient}
                                                            isSelected={isSelected}
                                                        />
                                                    );
                                                })}
                                            </div>                        
                                        </div>
                                    )}
                                </React.Fragment>
                            );                
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default RecipientPersonStudentsGuardians;