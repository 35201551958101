import { Modal, ModalSize, ModalType } from '@vismaux/react-nc4';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import FocusTrap from 'focus-trap-react';

interface ModalProps {
    isShowing: boolean,
    toggle: () => void,
    title: ReactNode,
    bodyContent: ReactNode,
    footerContent?: ReactNode,
    modalType?: ModalType,
    modalSize?: ModalSize
    handleClose?: () => void
    handleConfirm: () => void
}
let modalElement = document.getElementById('modal-root') as HTMLElement;
//needed to create dom element for test
if(!modalElement){
    modalElement = document.createElement('div');
    modalElement.setAttribute('id', 'modal-root');
    document.body.appendChild(modalElement);
}

const MessageModal = (props: ModalProps ) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        props.toggle();
        props.handleConfirm();
    };

    return props.isShowing ? createPortal(
        <FocusTrap
            focusTrapOptions={ {
                fallbackFocus: '#dialog'
            } }
        >
            <div
                id="dialog"
                tabIndex={-1}>
                <Modal
                    bodyContent = {props.bodyContent}
                    title = {props.title}
                    modalSize = {props.modalSize ? props.modalSize : 'modal-sm'} 
                    onModalClose={props.toggle}
                    modalType = {props.modalType}
                    footerContent = {props.footerContent ? props.footerContent : (
                        <div>
                            <button
                                type="button"
                                autoFocus
                                className="btn btn-primary bg-secondary action-button"
                                onClick={handleConfirm}>{t('prompts.confirm.send')}
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={props.toggle}>{t('prompts.confirm.cancel')}
                            </button>
                        </div>
                    )}
            
                />
            </div>
        </FocusTrap>,
        modalElement
    ) : null;
};

export default MessageModal;
