import { Icon } from '@vismaux/react-nc4';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MessageSent = () => {
    const {t} = useTranslation();
    return(
        <div className='sent-message-container'>
            <Icon
                //todo get paperplane icon
                name='send'
                dynamic
                size='sm' 
                className='sent-message-icon'/>
            <p className='sent-message-text'>{t('messageSent')}</p>
        </div>
    );
};

export default MessageSent;