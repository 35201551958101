import React, { useEffect } from 'react';
import { IFolder } from '../@types/folder';
import { ActionType } from '../context/ActionTypes';
import { MessagingState } from '../context/Context';
import DiscussionThreadService from '../services/DiscussionThreadService';
import FolderService, { FolderSelection } from '../services/FolderService';
import DiscussionThreadList from './DiscussionThreadList';
import DiscussionThreadWrapper from './DiscussionThreadWrapper';
import FolderList from './FolderList';
import SearchBar from './Search';
import SearchResultList from './SearchResultList';
import { useToast } from '../context/ToastContext';
import { useTranslation } from 'react-i18next';
import { LoadingState } from '../@types/loadingstates';
import { useUpdateInboxUnreadCount } from './hooks/useUpdateInboxUnreadCount';

const Home = () => {
    const { state: { searchResultView, searchPayload }, dispatch } = MessagingState();
    const toast = useToast();
    const { t } = useTranslation();
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();
    
    // Initial data loading
    useEffect(() => {

        const folders: IFolder[] = FolderService.getAll();
        const inboxFolder = folders[1];
        dispatch({
            type: ActionType.SET_ACTIVE_FOLDER,
            payload: inboxFolder
        });
        updateInboxUnreadCount();

        dispatch({
            type: ActionType.SET_SEARCH_PAYLOAD,
            payload: { ...searchPayload, folder: folders[FolderSelection.All] }
        });

        const fetchData = async () => {
            try {
                const response = await DiscussionThreadService.getReceived({pageNumber: 1, pageSize: 10});
                
                if (response.paginationData.CurrentPage !== 0) {
                    dispatch({
                        type: ActionType.SET_DISCUSSIONTHREADS,
                        payload: response.discussionThreads
                    });
                    dispatch({
                        type: ActionType.SET_PAGINATION_DATA,
                        payload: { currentPage: response.paginationData.CurrentPage, totalPages: response.paginationData.TotalPages }
                    });
                    
                    dispatch({
                        type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                        payload: LoadingState.Done
                    });
                }

            } catch {
                toast.createToast({
                    title: t('errors.messageLoadingError'),
                    toastType: 'danger'
                });
                dispatch({
                    type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                    payload: LoadingState.Error
                });
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <>
            <div
                id="search-row"
                className="row">
                <div className="col-md-2"/>
                <SearchBar />
            </div>
            <div
                id="main-row"
                className="row">
                <div className="col-md-2">
                    <FolderList />
                </div>
                <div className="col-md-3 middle-row">
                    {searchResultView ? (
                        <SearchResultList />
                    ) : (
                        <DiscussionThreadList />
                    )}
                </div>
                <div className="col-md-7">
                    <DiscussionThreadWrapper />
                </div>
            </div>
        </>
    );
};

export default Home;