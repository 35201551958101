import React from 'react';
import { RecipientSelectionTypes } from './RecipientsPersonList';
import { ListContainer, Spinner } from '@vismaux/react-vud';
import MyStudentCategoryButton from './MyStudentCategoryButton';
import MyGuardiansCategoryButton from './MyGuardiansCategoryButton';
import { MessagingState } from '../../context/Context';
import RecipientService from '../../services/RecipientService';
import MyTeachersCategoryButton from './MyTeachersCategoryButton';
import { LoadingState } from '../../@types/loadingstates';

const GuardianRecordsCategory = () => {
    const { state: { generalRecipientData } } = MessagingState();

    const guardianRecords = generalRecipientData?.otherGuardians;
    const myStudent = generalRecipientData?.myStudent;
    return (
        <>
            {myStudent && (
                <ListContainer>
                    <MyStudentCategoryButton
                        myStudentRecord={myStudent}
                        recipientType={RecipientSelectionTypes.MyStudent} />
                </ListContainer>
            )}
            <ListContainer>
                <MyTeachersCategoryButton
                    recipientType={RecipientSelectionTypes.OwnTeachers}
                    serviceMethod={RecipientService.getOwnTeachers.bind(RecipientService)}/>
            </ListContainer>
            {guardianRecords && guardianRecords.length > 0 && (
                <ListContainer>
                    <MyGuardiansCategoryButton
                        guardianRecords={guardianRecords}
                        recipientType={RecipientSelectionTypes.OtherGuardians} />
                </ListContainer>
            )}
            {generalRecipientData.loadingState === LoadingState.Loading && (
                <Spinner/>
            )}
        </>
    );
};

export default GuardianRecordsCategory;