import { Icon } from '@vismaux/react-vud';
import React from 'react';
import { IFolder } from '../@types/folder';
import { ActionType } from '../context/ActionTypes';
import { MessagingState, NEW_DISCUSSION_THREAD_ID } from '../context/Context';
import FolderService, { FolderSelection } from '../services/FolderService';
import FolderListItem from './FolderListItem';
import { useTranslation } from 'react-i18next';
import { useDiscussionThreads } from './hooks/useDiscussionThreads';
import classNames from 'classnames';
import * as amplitude from '@amplitude/analytics-browser';
import { useUpdateInboxUnreadCount } from './hooks/useUpdateInboxUnreadCount';

const FolderList = () => {
    const {t} = useTranslation();
    const { state: { activeFolder }, dispatch } = MessagingState();
    const { getDiscussionThreads } = useDiscussionThreads();
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();

    const folders = FolderService.getAll();

    const setNewMessageView = () => {
        dispatch({
            type: ActionType.SET_NEW_MESSAGE_VIEW, payload: {
                id: NEW_DISCUSSION_THREAD_ID,
                recipients: [],
                messages: [],
                canReplyToAll: false,
                canUserAddNewRecipients: false,
                canReplyToSender: false,
                canReplyToAllAuthorMessages: false,
            }
        });
    };

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLButtonElement>, folder:IFolder) => {
        if (ev.key === 'Enter') {
            selectFolder(folder);
        }
    };

    const selectFolder = async (folder:IFolder) => {
        amplitude.track('folder_selected', {
            folder_name : folder.name.replace('folders.', '')
        });
        // Update inbox unread count at the same time as updating received threads
        await Promise.allSettled([getDiscussionThreads(folder), folder.folderSelection === FolderSelection.Received ? updateInboxUnreadCount() : null]);
    };

    return (
        <nav 
            className="list-group full-height folder-list"
            title={t('titles.folderSelection')}>
            <button
                className="btn btn-primary bg-secondary action-button"
                id="new-message-button"
                onClick={() => setNewMessageView()}
                tabIndex={0}>
                <Icon
                    name='edit'
                    dynamic
                    size='sm' /><div className='folder-list-action-text'>{t('newMessage')}</div>
            </button>
            {
                folders
                    .filter(folder => !folder.hideInFolderList)
                    .map((folder: IFolder) => {
                        //TODO: Remove when all folders are implemented
                        const isDisabled = folder.folderSelection === FolderSelection.Disabled;
                        const selectedFolder = activeFolder?.id === folder.id;
                        const folderListItemWrapperClass = classNames('folder-list-group-item', {
                            disabled: isDisabled,
                            active: selectedFolder
                        });
                        return (
                            <button 
                                key={folder.id}
                                className={folderListItemWrapperClass}
                                aria-current={selectedFolder}
                                onClick={() => selectedFolder ? undefined : selectFolder(folder)}
                                onKeyDown={(e) => selectedFolder ? undefined : handleKeyDown(e, folder)}
                                tabIndex={0}
                            ><FolderListItem
                                    folder={folder}
                                    showIcons={true} 
                                    showUnread={folder.folderSelection === FolderSelection.Received}/>
                            </button>
                        );
                    })
            }
        </nav>
    );
};

export default FolderList;
