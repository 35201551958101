import { CanceledError } from 'axios';
import { LoadingState } from '../@types/loadingstates';
import { IRecords, IIndexRecords, IStudentRecords, IGradebook, ITeacherRecords, IPersonnelRecords, IWorkplaceInstructorRecords, ITrainingCoordinatorRecords, IRecord } from '../@types/recipients';
import { messagingHttpClient } from '../http-common';

class RecipientService {
    private abortController = new AbortController();
    private _loadingState: LoadingState = LoadingState.Loading;

    public get loadingState() : LoadingState {
        return this._loadingState;
    }

    public abortFetching() {
        this.abortController.abort();
        this.abortController = new AbortController();
        this._loadingState = LoadingState.Loading;
    }

    searchRecipients(searchText: string) {
        return messagingHttpClient.get<IRecords>('/ms/messaging/recipient/search?searchValue=' + searchText);
    }

    async getRecipients(): Promise<IIndexRecords> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<IIndexRecords>('/ms/messaging/recipient', { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return { indexRecords: [] };
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getOwnTeachers(): Promise<ITeacherRecords> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<ITeacherRecords>('/ms/messaging/recipient/ownteachers/', { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return { teacherRecords: [] };
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getRecipientsByClass(classId: string): Promise<IStudentRecords> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<IStudentRecords>('/ms/messaging/recipient/class/' + classId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return { studentRecords: [], oldStyleGuardianRecords: [], guardianRecords: [] };
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getCurrentGradebooks(): Promise<IGradebook> {
        return (await messagingHttpClient.get<IGradebook>('/ms/messaging/recipient/groups/current')).data;
    }

    async getPastGradebooks(): Promise<IGradebook> {
        return (await messagingHttpClient.get<IGradebook>('/ms/messaging/recipient/groups/past')).data;
    }

    async getFutureGradebooks(): Promise<IGradebook> {
        return (await messagingHttpClient.get<IGradebook>('/ms/messaging/recipient/groups/future')).data;
    }

    async getRecipientsByGroup(groupId: string): Promise<IStudentRecords> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<IStudentRecords>('/ms/messaging/recipient/group/' + groupId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return { studentRecords: [], oldStyleGuardianRecords: [], guardianRecords: [] };
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getTeachersInSchool(schoolId: string): Promise<IRecord[]> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<ITeacherRecords>('/ms/messaging/recipient/teachers/' + schoolId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data.teacherRecords;
        } catch (e) {
            if (e instanceof CanceledError) {
                return [];
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getPersonnelInSchool(schoolId: string): Promise<IRecord[]> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<IPersonnelRecords>('/ms/messaging/recipient/personnel/' + schoolId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data.personnelRecords;
        } catch (e) {
            if (e instanceof CanceledError) {
                return [];
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getWorkplaceInstructorsInSchool(schoolId: string): Promise<IRecord[]> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<IWorkplaceInstructorRecords>('/ms/messaging/recipient/workplaceinstructor/' + schoolId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data.workplaceInstructorRecords;
        } catch (e) {
            if (e instanceof CanceledError) {
                return [];
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }

    async getTrainingCoordinatorsInSchool(schoolId: string): Promise<IRecord[]> {
        try {
            this.abortFetching();
            const response = await messagingHttpClient.get<ITrainingCoordinatorRecords>('/ms/messaging/recipient/trainingcoordinator/' + schoolId, { signal: this.abortController.signal });
            this._loadingState = LoadingState.Done;
            return response.data.trainingCoordinatorRecords;
        } catch (e) {
            if (e instanceof CanceledError) {
                return [];
            } else {
                this._loadingState = LoadingState.Error;
                throw e;
            }
        }
    }
}

export default new RecipientService();
