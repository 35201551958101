import classNames from 'classnames';
import React from 'react';
import { IRecord } from '../../@types/recipients';
import { useTranslation } from 'react-i18next';
import { ActionType } from '../../context/ActionTypes';
import { RecipientSelectionTypes } from './RecipientsPersonList';
import { MessagingState } from '../../context/Context';
import RecipientService from '../../services/RecipientService';

interface IMyStudentCategoryButtonProps {
    myStudentRecord: IRecord;
    recipientType: RecipientSelectionTypes;
}

const MyStudentCategoryButton = ({ myStudentRecord, recipientType }: IMyStudentCategoryButtonProps) => {
    const { t } = useTranslation();
    const { state: { activeButtonId }, dispatch } = MessagingState();

    const setActiveButtonId = (id: string) => {
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: id
        });
    };

    const onClickCategory = async (event: React.MouseEvent<HTMLButtonElement>, record: IRecord) => {
        event.preventDefault();
        if (recipientType === activeButtonId) {
            return;
        }

        // In case my teachers button was, clicked, we reset its fetching before
        // opening recipient person list.
        RecipientService.abortFetching();
        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: recipientType,
                records: [record]
            }
        });

        setActiveButtonId(recipientType);
    };

    return (
        <button
            className={classNames('recipient-list-group-item-1', { 'active':  recipientType === activeButtonId })}
            onClick={(e) => {
                onClickCategory(e, myStudentRecord);
            }}>
            <div className='recipient-list-text'>
                {t(`selectRecipients.${recipientType}`)}
            </div>
        </button>
    );
};

export default MyStudentCategoryButton;