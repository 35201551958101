import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const messagingAxiosConfig: AxiosRequestConfig = {
    baseURL: '',
};

export const messagingHttpClient: AxiosInstance = axios.create(messagingAxiosConfig);

messagingHttpClient.interceptors.request.use(config => {
    const slug = window.frameElement?.getAttribute('data-slug');
    const baseUri= window.frameElement?.getAttribute('data-url');

    if(baseUri) {
        config.headers = config.headers || {};
        config.headers['Content-type'] = 'application/json';
        config.headers['X-WilmaUrl'] = baseUri;
        config.headers['X-WilmaSlug'] = slug || '';
    }

    return config;
});
