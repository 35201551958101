import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import MessagingProvider from './context/Context';
import './styles/shared.scss';
import { ToastContextProvider } from './context/ToastContext';
import { FeatureFlagProvider } from './context/FeatureFlagContext';
import './i18n';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <React.Suspense>
            <FeatureFlagProvider>
                <MessagingProvider>
                    <ToastContextProvider>
                        <App />
                    </ToastContextProvider>
                </MessagingProvider>
            </FeatureFlagProvider>
        </React.Suspense>
    </React.StrictMode >
);