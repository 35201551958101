import { useTranslation } from 'react-i18next';
import { IPagedResponsePayload } from '../../@types/discussionthread';
import { IFolder } from '../../@types/folder';
import { ActionType } from '../../context/ActionTypes';
import { MessagingState } from '../../context/Context';
import { useToast } from '../../context/ToastContext';
import DiscussionThreadService from '../../services/DiscussionThreadService';
import FolderService, { FolderSelection } from '../../services/FolderService';
import { LoadingState } from '../../@types/loadingstates';

export const useDiscussionThreads = () => {
    const { dispatch } = MessagingState();
    const { createToast } = useToast();
    const { t } = useTranslation();

    const getDiscussionThreads = async (folder: IFolder) => {
        //TODO: Remove when all folders are implemented
        if (folder.folderSelection == FolderSelection.Disabled) {
            createToast({
                title: t('errors.notImplemented'),
                toastType: 'danger'
            });
            return;
        }

        const folders: IFolder[] = FolderService.getAll();

        let response : IPagedResponsePayload = {discussionThreads: [], paginationData: {
            TotalPages: 1,
            CurrentPage: 0,
            PageSize: 0,
            TotalCount: 0
        }};

        let folderSelection = folder;

        dispatch({
            type: ActionType.SET_ACTIVE_FOLDER,
            payload: folder
        });
        dispatch({
            type: ActionType.SET_PAGINATION_DATA,
            payload: { currentPage: 1, totalPages: 1}
        });
        dispatch({
            type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
            payload: LoadingState.Loading
        });

        try {
            switch (folder.path) {
                case 'received':
                    response = (await DiscussionThreadService.getReceived());
                    folderSelection = folders[FolderSelection.All];
                    break;
    
                case 'sent':
                    response = (await DiscussionThreadService.getSent());
                    folderSelection = folder;
                    break;
            }

            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS,
                payload: response.discussionThreads
            });
            dispatch({
                type: ActionType.SET_PAGINATION_DATA,
                payload: { currentPage: response.paginationData.CurrentPage, totalPages: response.paginationData.TotalPages }
            });
            dispatch({
                type: ActionType.SET_SEARCH_PAYLOAD,
                payload: { searchText: '', folder: folderSelection }
            });
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Done
            });
        } catch {
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Error
            });
            createToast({
                title: t('errors.messageLoadingError'),
                toastType: 'danger'
            });
        }
        return response.discussionThreads;
    };

    return { getDiscussionThreads };
};