import { IDiscussionThread } from '../@types/discussionthread';
import { IUser } from '../@types/user';
import { t } from 'i18next';
import dayjs from 'dayjs';
import { IMessage } from '../@types/message';
import { IModalRecipient, IRecord } from '../@types/recipients';
import { StringOption } from '../components/WilmaAsyncSelect/WilmaAsyncSelect';
import { recordType } from '../@types/recipients';

// Get the formatted datetime of the newest message as a string
export const getNewestMessageSentAt = (discussionThread: IDiscussionThread) => {
    if (discussionThread.messages.length > 0 && discussionThread.messages[discussionThread.messages.length - 1].sentAt) {
        return getSentAtMessage(discussionThread.messages[discussionThread.messages.length - 1].sentAt, false);
    }
    return '';
};

const dateFormats = {
    title: {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hourCycle: 'h23',
    },
    messageWeek: {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
    },
    messageWeekSimple: {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
    },
    includeTime: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: 'h23',
    },
    messageDateSimple: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    }
};

export const getSentAtTitle = (date: Date) => {
    const sentDate = new Date(date);
    return t('messageSentAtTime', {
        val: sentDate,
        formatParams:
        {
            val: getFormat(dateFormats.title, true)
        },
        interpolation: { escapeValue: false }
    });
};

const getFormat = (dateFormat: object, includeTime: boolean) => {
    return includeTime ? { ...dateFormat, ...dateFormats.includeTime } : dateFormat;
};

export const getSentAtMessage = (date: Date, includeTime = true) => {
    const currentDate = dayjs();
    const sentDate = dayjs(date);
    const diffInDays = currentDate.diff(sentDate, 'day');

    let format;
    if (diffInDays < 7 && currentDate.day() >= sentDate.day()) {
        format = getFormat(dateFormats.messageWeekSimple, includeTime);
    } else {
        format = getFormat(dateFormats.messageDateSimple, includeTime);
    }

    return t('messageSentAtTime', {
        val: sentDate,
        formatParams: {
            val: format,
        },
        interpolation: { escapeValue: false }
    });
};

const isValidUser = (user: IUser | IRecord | StringOption) => user && user.label;

// Get the formatted name of the user
export const getUserDisplayName = (user?: IUser | IRecord | StringOption) => {
    if (user != undefined && isValidUser(user)) {
        const roleTypeString = user.roleType === '' ? '' : t('roleType.' + user.roleType);
        const label = user.label.replace('$roleType$', roleTypeString);
        return label;
    }

    return t('errors.unknownUser');
};

export const splitLabel = (label: string) => {
    const splitFromIndex = label.indexOf('(');
    const name = label.substring(0, splitFromIndex).trim();
    const role = label.substring(splitFromIndex).trim();
    return [name, role];
};

export const areStringArraysEqual = (options: string[], options2: string[]): boolean  => {
    if (options.length !== options2.length) return false;
    const values = options.map(o => o);
    return options2.every(option => values.includes(option));
};

export const getAddedRecipients = (messageIndex: number, discussionThread: IDiscussionThread, currentMessage: IMessage) => {
    // If message is the first one, everyone initially added can be ignored
    if (messageIndex === 0) return [];

    const {recipients} = discussionThread;
    //filter first message author as he should already be added to discussion thread
    const filteredRecipients = recipients.filter(r => r.roleGuid !== discussionThread.messages[0].author.roleGuid);
    // Convert the message.sentAt to a timestamp value for easy comparison
    const messageTime = dayjs(currentMessage.sentAt);
    // Filter the recipients to find those added after the current message
    const addedRecipients = filteredRecipients.filter((threadUser: IUser) => {
        // Convert the threadUser.addedAt to a timestamp value for easy comparison
        const addedAtDate = threadUser.addedAt ? dayjs(threadUser.addedAt) : 0;
        // Check if the recipient was added after the current message
        if (addedAtDate && addedAtDate.isAfter(messageTime)) {
            // Previous message is guaranteed when we skip first index
            const prevMessage = discussionThread.messages[messageIndex - 1];
            const prevMessageTime = dayjs(prevMessage.sentAt);

            // Get the next message if it exists
            const nextMessage = discussionThread.messages[messageIndex + 1];
            const nextMessageTime = nextMessage ? dayjs(nextMessage.sentAt) : Infinity;

            // Check if the recipient's addedAtDate is within the range of the current message and the next message
            return addedAtDate.isAfter(prevMessageTime) && addedAtDate.isBefore(nextMessageTime) || nextMessageTime == Infinity;
        }
        // If the recipient was not added after the current message or it's the first message, exclude it
        return false;
    });
    return addedRecipients;
};

export const getThreadUnreadMessages = (discussionThread: IDiscussionThread) => {
    return discussionThread.messages.filter(message => message.isRead === false);
};

export const getUserDisplayNames = (users: IUser[], showFive: boolean) => {
    const filteredUsers = users.filter(isValidUser);
    const displayedUsers = showFive ? filteredUsers.slice(0, 5).map(getUserDisplayName) : filteredUsers.map(getUserDisplayName);

    let result = displayedUsers.join(', ');
    if (filteredUsers.length > 5) {
        showFive ? result += '...' : '';
    }

    return result;
};

export const getRecordsToOptions = (allRecipients: IRecord[], recordType: recordType): StringOption[]  => {
    if (recordType === 'studentGuardianRecords') {
        const allowedGuardianRecords = allRecipients
            .flatMap((record: IRecord) => record.guardianRecords || []);
        return mapRecordsToStringOptions(allowedGuardianRecords);
    }
    
    return mapRecordsToStringOptions(allRecipients);
};

const mapRecordsToStringOptions = (records: IRecord[]): StringOption[] => {
    return records
        .filter((record: IRecord) => record.allowSendingMessage)
        .map((record: IRecord): StringOption => {
            return { 
                label: record.label, 
                value: record.roleGuid,
                name: record.name,
                nameAbbreviation: record.nameAbbreviation,
                allowMessaging: record.allowSendingMessage,
                roleType: record.roleType,
                schoolNames: record.schoolNames,
                extraInfo: record.extraInfo,
                isGroup: false,
            };
        });
};

export const getGroupSelectionLabel = (recordType: recordType, selectedSchoolId: string) => {
    if(selectedSchoolId === '0' && recordType === 'teacherRecords') {
        return t('selectRecipients.ownTeachers');
    }

    switch (recordType) {
        case 'studentGuardianRecords':
            return `(${t('selectRecipients.guardians')})`;
        case 'studentRecords':
            return `(${t('selectRecipients.students')})`;
        case 'personnelRecords':
            return `(${t('selectRecipients.personnel')})`;
        case 'teacherRecords':
            return `(${t('selectRecipients.teachers')})`;
        case 'trainingCoordinatorRecords':
            return `(${t('selectRecipients.trainingCoordinators')})`;
        case 'workplaceInstructorRecords':
            return `(${t('selectRecipients.workplaceInstructors')})`;
    }
};

export const getLabel = (option: IModalRecipient) => {
    if(option === undefined || option === null) return '';
    if(option.isGroup) {
        return option.value.split('$')[1];
    } else {
        return option.options.length > 0 ? getUserDisplayName(option.options[0]) : '';
    }
};

export const getCheckboxLabel = (recordType: recordType) => {
    switch (recordType) {
        case 'studentRecords':
            return t('selectRecipients.selectAllStudents');
        case 'studentGuardianRecords':
            return t('selectRecipients.selectAllGuardians');
        default:
            return t('selectRecipients.selectAll');
    }
};
