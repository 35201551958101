import React, { useEffect } from 'react';
import { MessagingState } from '../context/Context';
import { useTranslation } from 'react-i18next';
import DiscussionThreadPagination from './DiscussionThreadPagination';

const DiscussionThreadList = () => {
    const { t } = useTranslation();
    const { state: { activeDiscussionThread, activeFolder } } = MessagingState();

    useEffect(() => {
        document.getElementById('discussion-thread-back-button')?.focus();
    }, [activeDiscussionThread]);

    return (
        <>
            <ul className="list-group full-height">
                <li className="list-group-item list-group-title">
                    <div className="row">
                        <div>
                            <span className="discussion-thread-list-group-title">{activeFolder && t(activeFolder.name)}</span>
                        </div>
                    </div>
                </li>
                <DiscussionThreadPagination />
            </ul>
        </>
    );
};

export default DiscussionThreadList;
