import React from 'react';
import { ActionType } from '../../context/ActionTypes';
import { MessagingState } from '../../context/Context';
import { getCheckboxLabel, getRecordsToOptions } from '../../utils/utils';
import { recordType } from '../../@types/recipients';
import { Checkbox } from '@vismaux/react-vud';

interface SelectAllCheckboxProps {
    id: string;
    selectedLabel: string;
    recordType: recordType;
}

const SelectAllCheckbox = ({ id, selectedLabel, recordType }: SelectAllCheckboxProps) => {
    const { state: { modalRecipients, recipientListData}, dispatch } = MessagingState();

    const activeLabelId = `${id}$${selectedLabel}`;

    const isCheckBoxChecked = (activeLabelId: string) => {
        return modalRecipients.modalRecipientOptions.some(option => option.isGroup && option.value === activeLabelId);
    };

    const onClickSelectAll = () => {
        const isChecked = isCheckBoxChecked(activeLabelId);

        const recordsToOptions = getRecordsToOptions(recipientListData.records, recordType);
        const modalRecipientOption = {value: activeLabelId, options: recordsToOptions, isGroup: true};
        const filteredModalRecipientOptions = modalRecipients.modalRecipientOptions.filter(option => !recordsToOptions.map(record => record.value).includes(option.value));

        const newRecipientOptions = isChecked
            ? modalRecipients.modalRecipientOptions.filter(option => option.value !== activeLabelId)
            : [...filteredModalRecipientOptions, modalRecipientOption];

        dispatch({
            type: ActionType.SET_MODAL_RECIPIENTS,
            payload: { modalRecipientOptions: newRecipientOptions}
        });
    };

    const checkBoxIsDisabled = recipientListData.records.filter(record => record.allowSendingMessage === true).length === 0;

    return (
        <div className='select-all-checkbox'>
            <Checkbox
                label={getCheckboxLabel(recordType)}
                checked={isCheckBoxChecked(activeLabelId)}
                onChange={() => onClickSelectAll()}
                disabled={checkBoxIsDisabled}/>
        </div>
    );
};

export default SelectAllCheckbox;