export enum ActionType {
  SET_ACTIVE_DISCUSSIONTHREAD = 'SET_ACTIVE_DISCUSSIONTHREAD',
  SET_DISCUSSIONTHREADS = 'SET_DISCUSSIONTHREADS',
  SET_UNREAD_INBOX_COUNT = 'SET_UNREAD_INBOX_COUNT',
  SET_ACTIVE_FOLDER = 'SET_ACTIVE_FOLDER',
  SET_MESSAGE_AS_READ_SUCCESS = 'SET_MESSAGE_AS_READ_SUCCESS',
  SET_NEW_MESSAGE_VIEW = 'SET_NEW_MESSAGE_VIEW',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_SEARCH_VIEW = 'SET_SEARCH_VIEW',
  SET_MESSAGE_SENT_VIEW = 'SET_MESSAGE_SENT_VIEW',
  SET_SEARCH_DISCUSSIONTHREADS = 'SET_SEARCH_DISCUSSIONTHREADS',
  SET_SEARCH_FOCUS = 'SET_SEARCH_FOCUS',
  SET_SEARCH_PAYLOAD = 'SET_SEARCH_PAYLOAD',
  UPDATE_DISCUSSION_THREAD = 'UPDATE_DISCUSSION_THREAD',
  SET_SELECTED_RECIPIENTS = 'SET_SELECTED_RECIPIENTS',
  SET_MODAL_RECIPIENTS = 'SET_MODAL_RECIPIENTS',
  SET_MESSAGE = 'SET_MESSAGE',
  SET_RECIPIENTLIST_VIEW = 'SET_RECIPIENTLIST_VIEW',
  SET_SELECTED_SCHOOL = 'SET_SELECTED_SCHOOL',
  SET_ACTIVE_BUTTON_ID = 'SET_ACTIVE_BUTTON_ID',
  SET_GENERAL_RECIPIENT_DATA = 'SET_GENERAL_RECIPIENT_DATA', // Used when opening recipient modal to find out which categories to present
  MODAL_IS_RECIPIENT_IN_VIEW = 'MODAL_IS_RECIPIENT_IN_VIEW', // Used to count amount of hidden recipient labels in modal
  SET_PAGINATION_DATA = 'SET_CURRENT_PAGE', // Used for pagination
  SET_DISCUSSIONTHREADS_LOADING = 'SET_DISCUSSIONTHREADS_LOADING',
}